import * as React from 'react';

import { CanvasFrame } from 'editor-canvas';
import { HTMLEditorView, RTValue, getCSSPropsFromRTValue } from 'ui-components';
import { PharmacyOnDutyNearby } from 'utils';

import type { PharmacistOnDutyFrame } from '../types';

export default function PharmacistOnDutyFrame(props: {
    gettext: (text: string) => string;
    frame: CanvasFrame & PharmacistOnDutyFrame;
    getPharmaciesOnDutyNearby: (numPharmacists: number, locale: string) => PharmacyOnDutyNearby[];
    style?: React.CSSProperties;
    locale: string;
}) {
    const { gettext, frame, style, locale, getPharmaciesOnDutyNearby } = props;
    const pharmaciesOnDuty = getPharmaciesOnDutyNearby(frame.numPharmacists, locale);
    let html = '';
    const numCols = frame.numCols || 1;
    const columnWidth = (100 / numCols).toFixed(4);

    html += `<div style="display: flex; flex-wrap: wrap;">`;

    for (const pharmacyOnDuty of pharmaciesOnDuty) {
        html += `
        <div style="flex: 0 0 ${columnWidth}%; box-sizing: border-box; padding: 8px;">
            <div>${pharmacyOnDuty.pharmacy.name}</div>
            <div>${pharmacyOnDuty.pharmacy.getDescription(gettext) || ''}</div>
            <div>${pharmacyOnDuty.pharmacy.phoneNumber || ''}</div>
            <div>${pharmacyOnDuty.pharmacy.address}</div>
            <div>${pharmacyOnDuty.pharmacy.geoDescription || ''}</div>
        </div>
    `;
    }

    html += `</div>`;

    const frameStyle = new RTValue('', frame.textStyle);

    return (
        <HTMLEditorView
            style={style}
            innerStyle={getCSSPropsFromRTValue(frameStyle)}
            value={html}
        />
    );
}
